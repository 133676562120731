var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_c('v-card',[(_vm.orgs)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orgs,"items-per-page":25},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'orgmanagerView', params: {id: item.id}}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.license",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.license)+" "),(item.seats)?_c('span',{staticClass:"text-muted ml-2"},[_vm._v("("+_vm._s(item.seats)+")")]):_vm._e()]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),(item.remarks)?_c('v-icon',{attrs:{"x-small":"","title":item.remarks}},[_vm._v("fa fa-comment")]):_vm._e()]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.created_on,'L LT'))+" ")]}}],null,false,3426601726)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }